import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useAinesPage } from "../services/firestore";
import ReactMarkdown from "react-markdown";

const Highlight = ({ title }) => {
  return (
    <section className="highlight lg:text-center px-4">
      <div className="container mx-auto p-4 lg:p-32">
        <h1 className="text-3xl text-center">{title}</h1>
      </div>
    </section>
  );
};

const Description = ({ highlight, image }) => {
  return (
    <section className="description flex flex-col-reverse lg:text-xl lg:flex-row lg:items-stretch">
      <img src={image.src} alt={image.image} />
      <div className="text px-4 py-8 lg:flex-1 lg:px-16">
        <ReactMarkdown>{highlight}</ReactMarkdown>
      </div>
    </section>
  );
};

const ServirPage = ({ data }) => {
  const page = useAinesPage(data.allAinesPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner}>
      {page && <Highlight title={page.title}></Highlight>}

      {page && (
        <Description
          highlight={page.highlight}
          image={page.image}
        ></Description>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allAinesPages {
      nodes {
        title
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        image {
          src
          image
        }
      }
    }
  }
`;

export default ServirPage;
